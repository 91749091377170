export const host = (() => {
  const environmentMappings = {
    test: 'https://nexus-service.signalplus.net',
    production: 'https://nexus-service.signalplus.com',
    development: 'http://localhost:8081'
  };
  const environment = process.env.BUILD_ENV || 'development';
  console.log('process.env.BUILD_ENV:', process.env.BUILD_ENV);
  console.log('process.env.VERSION:', process.env.VERSION);
  console.log('environment：', environment);
  return environmentMappings[environment];
})();
//user
export const adminLoginUrl = `${host}/tech/admin/login`;
export const adminUserInfoUrl = `${host}/tech/admin/info`;

//tenant
export const tenantListUrl = `${host}/tech/tenant/list`;
export const addTenantUrl = `${host}/tech/tenant/add`;
export const updateTenantUrl = `${host}/tech/tenant/update`;
export const deleteTenantUrl = `${host}/tech/tenant/delete`;

//consoleInstance
export const consoleInstanceListUrl = `${host}/tech/console-instance/list`;
export const addConsoleInstanceUrl = `${host}/tech/console-instance/add`;
export const updateConsoleInstanceUrl = `${host}/tech/console-instance/update`;
export const deleteConsoleInstanceUrl = `${host}/tech/console-instance/delete`;

//botInstance
export const botInstanceListUrl = `${host}/tech/bot-instance/list`;
export const addBotInstanceUrl = `${host}/tech/bot-instance/add`;
export const updateBotInstanceUrl = `${host}/tech/bot-instance/update`;
export const deleteBotInstanceUrl = `${host}/tech/bot-instance/delete`;

//botSupportCase
export const botSupportCaseListUrl = `${host}/tech/bot-support-case/list`;
export const addBotSupportCaseUrl = `${host}/tech/bot-support-case/add`;
export const updateBotSupportCaseUrl = `${host}/tech/bot-support-case/update`;
export const deleteBotSupportCaseUrl = `${host}/tech/bot-support-case/delete`;

//apiAuth
export const apiAuthListUrl = `${host}/tech/api-auth/list`;
export const addApiAuthUrl = `${host}/tech/api-auth/add`;
export const updateApiAuthUrl = `${host}/tech/api-auth/update`;
export const deleteApiAuthUrl = `${host}/tech/api-auth/delete`;

//console version
export const consoleVersionListUrl = `${host}/tech/console-version/list`;
export const addConsoleVersionUrl = `${host}/tech/console-version/add`;
export const updateConsoleVersionUrl = `${host}/tech/console-version/update`;
export const deleteConsoleVersionUrl = `${host}/tech/console-version/delete`;

//bot version
export const botVersionListUrl = `${host}/tech/bot-version/list`;
export const addBotVersionUrl = `${host}/tech/bot-version/add`;
export const updateBotVersionUrl = `${host}/tech/bot-version/update`;
export const deleteBotVersionUrl = `${host}/tech/bot-version/delete`;
export const downloadBotVersionUrl = `${host}/tech/bot-version/download`;

//parameter schema
export const parameterSchemaListUrl = `${host}/tech/parameter-schema/list`;
export const parameterSchemaDetailUrl = `${host}/tech/parameter-schema/detail`;
export const addParameterSchemaUrl = `${host}/tech/parameter-schema/add`;
export const updateParameterSchemaUrl = `${host}/tech/parameter-schema/update`;
export const duplicateParameterSchemaUrl = `${host}/tech/parameter-schema/duplicate`;
export const deleteParameterSchemaUrl = `${host}/tech/parameter-schema/delete`;

//parameter group
export const parameterGroupListUrl = `${host}/tech/parameter-template/list`;
export const addParameterTemplateUrl = `${host}/tech/parameter-template/add`;
export const updateParameterTemplateUrl = `${host}/tech/parameter-template/update`;
export const deleteParameterTemplateUrl = `${host}/tech/parameter-template/delete`;

// parameter
// export const parameterListUrl = `${host}/tech/parameter/list`;
export const addParameterUrl = `${host}/tech/parameter/add`;
export const addChildParameterUrl = `${host}/tech/parameter/add-child`;
export const updateParameterUrl = `${host}/tech/parameter/update`;
export const deleteParameterUrl = `${host}/tech/parameter/delete`;

//parameter currency default value
export const parameterCurrencyDefaultValueListUrl = `${host}/tech/parameter-currency-default-value/list`;
export const addParameterCurrencyDefaultValueUrl = `${host}/tech/parameter-currency-default-value/add`;
export const updateParameterCurrencyDefaultValueUrl = `${host}/tech/parameter-currency-default-value/update`;
export const deleteParameterCurrencyDefaultValueUrl = `${host}/tech/parameter-currency-default-value/delete`;

//parameter bot default value
export const parameterBotDefaultValueListUrl = `${host}/tech/parameter-bot-default-value/list`;
export const addParameterBotDefaultValueUrl = `${host}/tech/parameter-bot-default-value/add`;
export const updateParameterBotDefaultValueUrl = `${host}/tech/parameter-bot-default-value/update`;
export const deleteParameterBotDefaultValueUrl = `${host}/tech/parameter-bot-default-value/delete`;

//bot config
export const botConfigListUrl = `${host}/tech/bot-config/list`;
export const addBotConfigUrl = `${host}/tech/bot-config/add`;
export const updateBotConfigUrl = `${host}/tech/bot-config/update`;
export const deleteBotConfigUrl = `${host}/tech/bot-config/delete`;

//expiryDay & instruments
export const expiryDaysUrl = `${host}/tech/tenor/expiryDays`;
export const instrumentsUrl = `${host}/tech/tenor/instruments`;